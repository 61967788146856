import React from 'react'
import CenteredPaddedSlideshow from 'components/Slideshows/CenteredPaddedSlideshow'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { getProductDiv } from 'js/utils'

const Block = ({ content, locale }) => {
  const { pretitle, title, collectionProducts, product } = content
  const otherCollectionProducts = collectionProducts.filter(
    (thisProduct) => thisProduct.slug !== product.slug
  )
  const otherCollectionProductsLimit = otherCollectionProducts.slice(0, 5)
  const items = otherCollectionProductsLimit.map((item) => {
    // const productDivLocale = getProductDiv(item, locale)
    const productCollection = {
      title: product.collection.data.frontmatter[locale].title,
      productDivSlug:
        product.collection.data.frontmatter[locale].productDiv.slug,
    }
    return {
      // pretitle: item.collection?.data.frontmatter[locale].title,
      pretitle: productCollection.title,
      title: item.title,
      image: item.image,
      // link: `/${productDivLocale.slug}/${item.slug}`,
      link: `/${productCollection.productDivSlug}/${item.slug}`,
    }
  })

  return (
    <div className='pad-v-standard'>
      <Inview className='container fade-in up'>
        <div className={styles.title}>
          {pretitle && <h5>{pretitle}</h5>}
          {title && <h3>{title}</h3>}
        </div>
      </Inview>
      <Inview className='fade-in up'>
        <CenteredPaddedSlideshow
          items={items}
          id={Math.floor(Math.random() * 1000000)}
        />
      </Inview>
    </div>
  )
}

export default Block
