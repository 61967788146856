import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, locale }) => {
  const {
    productDiv,
    pretitle,
    pretitleLink,
    title,
    excerpt,
    image,
    description,
    linkTmall,
  } = content

  const strings = {
    en: {
      inquiry: 'inquiry',
      buy: 'buy',
    },
    zh: {
      inquiry: '咨询购买',
      buy: '购买',
    },
  }
  const tr = strings[locale]
  const buttonLinkContent = {
    furniture: {
      text: tr.inquiry,
      linkType: 'qr',
    },
    teaware: {
      text: tr.buy,
      linkType: 'external',
      to: linkTmall,
    },
  }

  return (
    <div className='block pad-v-standard first-add-v-pad'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && pretitleLink && (
            <h5>
              <LinkWrap to={pretitleLink}>{pretitle}</LinkWrap>
            </h5>
          )}
          {pretitle && !pretitleLink && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
          {excerpt && (
            <div className={`p-smaller ${styles.excerpt}`}>
              <MarkdownWrap body={excerpt} />
            </div>
          )}
        </Inview>
        <Inview className={`grid-12 ${styles.image} fade-in up`}>
          <ImageWrap image={image} />
        </Inview>
        <Inview className={`${styles.text} fade-in up`}>
          <div className={`p-smaller ${styles.body}`}>
            <MarkdownWrap body={description} />
          </div>
        </Inview>
        {productDiv.slug === 'furniture' && (
          <Inview className={`${styles.buttonWrap} fade-in up`}>
            <ButtonLink content={buttonLinkContent.furniture} />
          </Inview>
        )}
        {productDiv.slug === 'teaware' && linkTmall && (
          <Inview className={`${styles.buttonWrap} fade-in up`}>
            <ButtonLink content={buttonLinkContent.teaware} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default Block
