import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'
import { getProductDiv } from 'js/utils'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { product, locale } = pageContext
  const productLocale = product.frontmatter[locale]
  const productDivLocale = getProductDiv(productLocale, locale)

  const collectionProducts = data.collectionProducts.nodes

  const strings = {
    en: {
      products: 'Products',
    },
    zh: {
      products: '产品',
    },
  }
  const tr = strings[locale]
  const content = {
    product: productLocale,
    productDiv: productDivLocale,
    collectionProducts,
    title: `${productDivLocale.titleShorter} - ${productLocale.title}`,
    seo: productLocale.seo,
  }

  return (
    <>
      <Seo content={content} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($productCollectionSlug: String) {
    collectionProducts: allMarkdownRemark(
      filter: {
        frontmatter: {
          en: {
            templateKey: { eq: "product" }
            collection: { eq: $productCollectionSlug }
          }
        }
      }
    ) {
      nodes {
        frontmatter {
          en {
            title
            slug
            collection
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          zh {
            title
            slug
            collection
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
