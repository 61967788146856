import React from 'react'
import Inview from 'components/Inview'
import LineLink from 'components/LineLink'
import * as styles from './styles.module.scss'

const Block = ({ content, locale, hasDownloads, checkLogin }) => {
  const { linkTaobao } = content
  if (!hasDownloads & !linkTaobao) return false
  const strings = {
    en: {
      downloadResources: 'Download Resources',
      // careInstructions: 'Care Instructions',
      viewOnTaobao: 'View on Taobao',
    },
    zh: {
      downloadResources: '资料下载',
      // careInstructions: '*Care Instructions',
      viewOnTaobao: '立即购买',
    },
  }
  const tr = strings[locale]

  return (
    <div className='pad-v-standard'>
      <Inview className='container fade-in up'>
        <div className={styles.linksWrap}>
          <div className={styles.links}>
            {hasDownloads && (
              <LineLink
                content={{
                  text: tr.downloadResources,
                  linkType: 'ui',
                }}
                onClick={() => checkLogin()}
              />
            )}
            {/* <LineLink
              content={{
                text: tr.careInstructions,
                linkType: 'placeholder',
              }}
            /> */}
            {/* {linkTaobao && (
              <LineLink
                content={{
                  to: linkTaobao,
                  text: tr.viewOnTaobao,
                  linkType: 'external',
                }}
              />
            )} */}
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Block
