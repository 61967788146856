import React from 'react'
import ButtonLink from 'components/ButtonLink'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, layoutProps }) => {
  const { pretitle, title, body, image, link } = content || {}
  return (
    <Inview className={`block fade-in up bg-dark  ${styles.component}`}>
      <div className='container'>
        <HeaderVersionWrap layoutProps={layoutProps}>
          <div className={`grid-12 ${styles.grid}`}>
            <div className={styles.text}>
              <h6>{pretitle}</h6>
              <h3>{title}</h3>
              <div className='p-smaller'>
                <MarkdownWrap body={body} />
              </div>
              <div className={styles.link}>
                <ButtonLink content={link} className='darker' />
              </div>
            </div>
            <div className={styles.image}>
              <ImageWrap image={image} />
            </div>
          </div>
        </HeaderVersionWrap>
      </div>
    </Inview>
  )
}

export default Block
