import React from 'react'
import LoginForm from 'components/Forms/Login'
import IconCloseSvg from '../../assets/svg/icons/icon-close.svg'
import * as styles from './styles.module.scss'

const LoginOverlay = ({ loginOverlayShow, setLoginOverlayShow, locale }) => {
  const strings = {
    en: {
      downloadFiles: 'Download Files',
    },
    zh: {
      downloadFiles: '下载文件',
    },
  }
  const tr = strings[locale]

  return (
    <div
      className={`${styles.component} ${loginOverlayShow ? styles.show : ''}`}
    >
      <div className={styles.bg} onClick={() => setLoginOverlayShow(false)} />
      <div className={styles.overlayWrap}>
        <div className={styles.overlay}>
          <div>
            <a
              className={styles.closeIcon}
              onClick={() => setLoginOverlayShow(false)}
            >
              <IconCloseSvg />
            </a>
            <div className={styles.inner}>
              <h5>{tr.downloadFiles}</h5>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginOverlay
