import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Field, Form } from 'react-final-form'
import ButtonLink from 'components/ButtonLink'
import LinkWrap from 'components/LinkWrap'
import LoaderSvg from 'assets/svg/ui/loader.svg'
import { LocaleContext } from 'gatsby-theme-i18n'
import * as styles from './styles.module.scss'
import axios from 'axios'
import { objToFormData } from 'js/utils'

const Component = () => {
  const locale = React.useContext(LocaleContext)
  const strings = {
    en: {
      existingUsers: 'Existing Users',
      email: 'Email',
      password: 'Password',
      lostPassword: 'Lost your password?',
      logIn: 'Log In',
      errorNoEmail: 'Error: email not found.',
      errorWrongPassword: 'Error: password incorrect',
      newUsers: 'New Users',
      register: 'Register',
      registerToDownload:
        'Register to gain access to downloadable high resolution images, 2D and 3D files, and spec sheets.',
    },
    zh: {
      existingUsers: '已注册用户',
      email: '邮箱',
      password: '密码',
      lostPassword: '遗失密码？',
      logIn: '登陆',
      errorNoEmail: '错误提示：邮箱未注册',
      errorWrongPassword: '错误提示：密码不正确',
      newUsers: '新用户',
      register: '注册',
      registerToDownload:
        '注册用户以下载的高分辨率产品图、2D 和 3D 文件以及规格表。',
    },
  }
  const tr = strings[locale]

  return (
    <div className={styles.component}>
      <NewUsers tr={tr} />
      <ExistingUsers tr={tr} locale={locale} />
    </div>
  )
}

export default Component

const NewUsers = ({ tr }) => {
  return (
    <div>
      <h6>{tr.newUsers}</h6>
      <p className='smaller'>{tr.registerToDownload}</p>
      <div className='submit-wrap'></div>
      <ButtonLink
        content={{
          to: '/account/create-account',
          text: tr.register,
        }}
      />
    </div>
  )
}

const ExistingUsers = ({ tr, locale }) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (values) => {
    setError(null)
    setIsLoading(true)

    const errorNoEmail = () => {
      setError(null)
      setTimeout(() => {
        setError('noEmail')
        setIsLoading(false)
      }, 500)
    }
    const errorWrongPassword = () => {
      setError('')
      setTimeout(() => {
        setError('wrongPassword')
        setIsLoading(false)
      }, 500)
    }
    const logIn = (user) => {
      setError(null)
      setIsLoading(false)
      sessionStorage.setItem('user', user.email)
      const localePrefix = locale === 'en' ? '/en' : ''
      navigate(`${localePrefix}/account/welcome`)
    }

    const url = process.env.GATSBY_PHP_URL

    const data = {
      function: 'logInUser',
      email: values.email,
      password: values.password,
    }
    const formData = objToFormData(data)

    axios.post(url, formData).then((response) => {
      if (response.data === 'errorNoEmail') errorNoEmail()
      if (response.data === 'errorWrongPassword') errorWrongPassword()
      if (response.data.email) {
        const user = {
          email: values.email,
        }
        logIn(user)
      }
    })
  }

  return (
    <div>
      <h6>{tr.existingUsers}</h6>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={handleSubmit}
            // noValidate
          >
            <div className='fields'>
              <div className='field-row'>
                <Field
                  name='email'
                  component='input'
                  type='email'
                  placeholder={tr.email}
                  required
                />
              </div>
              <div className='field-row'>
                <Field
                  name='password'
                  component='input'
                  type='password'
                  placeholder={tr.password}
                  required
                />
              </div>
            </div>

            <p className={`smallest ${styles.lostPassword}`}>
              <LinkWrap to='/account/request-new-password'>
                {tr.lostPassword}
              </LinkWrap>
            </p>

            <div className={'submit-wrap ' + (isLoading ? 'is-loading' : '')}>
              <div className='loader-wrap'>
                <ButtonLink
                  content={{
                    text: tr.logIn,
                    linkType: 'submit',
                    isDisabled: isLoading,
                  }}
                />
                <div className='loader'>
                  <LoaderSvg />
                </div>
              </div>
            </div>
            <ul className='error-holder'>
              <li className='placeholder'>{tr.errorNoEmail}</li>
              <li className={error === 'noEmail' ? 'show' : ''}>
                {tr.errorNoEmail}
              </li>

              <li className={error === 'wrongPassword' ? 'show' : ''}>
                {tr.errorWrongPassword}
              </li>
            </ul>
          </form>
        )}
      />
    </div>
  )
}
