import React, { useState } from 'react'
import $ from 'jquery'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import saveAs from 'file-saver'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'
import CheckboxSvg from '../../assets/svg/icons/icon-check.svg'
import Icon3dsSvg from '../../assets/svg/icons/icon-file-3ds.svg'
import IconDwgSvg from '../../assets/svg/icons/icon-file-dwg.svg'
import IconPdfSvg from '../../assets/svg/icons/icon-file-pdf.svg'

import * as styles from './styles.module.scss'

const Component = ({ products, sourcePage, locale }) => {
  const [selection, setSelection] = useState([])

  const strings = {
    en: {
      packagingFiles: 'packaging files…',
      progress: 'progress: ',
      downloadInProgress:
        'Download already in progress: please wait for it to finish.',
      done: 'done!',
      catProductImages: 'Hi Res Product Images',
      catSceneImages: 'Scene Images',
      cat2d3dFiles: '2D & 3D Files',
      catSpecSheets: 'Spec Sheets',
      selectAll: 'Select All',
      downloadFiles: 'Download',
    },
    zh: {
      packagingFiles: '正在打包文件…',
      progress: '进度：',
      downloadInProgress: '文件正在打包中，请等完成后再试。',
      done: '完成！',
      catProductImages: '高分辨率产品图',
      catSceneImages: '场景照',
      cat2d3dFiles: '2D及3D文件',
      catSpecSheets: '规格说明书',
      selectAll: '选择全部',
      downloadFiles: '下载文件',
    },
  }
  const tr = strings[locale]

  const toggleSelection = (e) => {
    const thisSelection = e.target.getAttribute('data-url')
    let newSelection = [...selection]
    if (newSelection.includes(thisSelection)) {
      newSelection = newSelection.filter(
        (selection) => selection != thisSelection
      )
    } else {
      newSelection.push(thisSelection)
    }
    setSelection(newSelection)
  }

  const getAllItems = () => {
    let allItems = []
    if (products) {
      products.forEach((product) => {
        if (product.downloads) {
          // some products don't have any downloads
          product.downloads.forEach((item, i) => {
            allItems.push(item.download.publicURL)
          })
        }
      })
    }
    return allItems
  }

  const selectAll = () => {
    setSelection(getAllItems())
  }

  const deselectAll = () => {
    setSelection([])
  }

  // zipDL = (downloadSet, files) => {
  const zipDL = (e) => {
    const downloadSet = $('.download-set')
    const downloadSetGetLinks = (downloadSet) => {
      const links = downloadSet.find('.files').find('a')
      let files = []
      for (let i = 0; i < links.length; i++) {
        const thisLink = $(links[i])
        if (thisLink.hasClass('on')) {
          let file = {}
          file.url = thisLink.data('url')
          file.filename = thisLink.data('filename')
          file.size = thisLink.data('size')
          files.push(file)
        }
      }
      return files
    }

    const files = downloadSetGetLinks(downloadSet)

    const zipUI = {
      showMessage: function (html) {
        var progressResult = $('.download-set.downloading .progress-result')
        // setTimeout(function() {
        progressResult.html(html)
        // }, 0)
      },
      showError: function (text) {
        $('.download-set.downloading .progress-result')
          .addClass('alert alert-danger')
          .text(text)
      },
      updatePercent: function (percent) {
        $('.download-set.downloading .progress-bar-outer')
          .find('.progress-bar')
          .attr('aria-valuenow', percent)
          .css({ width: percent + '%' })
      },
      progressInit: function () {
        var downloadProgress = $('.download-set.downloading .progress')
        downloadProgress.addClass(styles.show)
        // downloadProgress.addClass(styles.noTransition)
        zipUI.updatePercent(0)
        zipUI.showMessage(tr.packagingFiles)
        var t2 = setTimeout(function () {
          // downloadProgress.removeClass(styles.noTransition)
        }, 10)
      },
      progressUpdate: function (percent, file) {
        var filesizeMB = file.size / 1000000
        var filesizeDisplay = Math.round(filesizeMB * 10) / 10
        var msg = tr.progress + percent.toFixed(0) + '%'
        if (file)
          msg += `<span class="file">&nbsp;&nbsp;-&nbsp;${file.filename} (${filesizeDisplay} MB)</span>`
        zipUI.showMessage(msg)
        zipUI.updatePercent(percent)
      },
    }
    const urlToPromise = (url) => {
      return new Promise(function (resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      })
    }
    const getNextFile = (files, currentFile) => {
      for (let i = 0; i <= files.length; i++) {
        var thisFilename = files[i].url.substring(
          files[i].url.lastIndexOf('/') + 1
        )
        if (currentFile === thisFilename) {
          if (i + 1 === files.length) {
            return ''
          } else {
            let nextFile = files[i + 1]
            return nextFile
          }
        }
      }
      return ''
    }

    var stamp = +new Date()
    var filename = 'zhuoyu_' + stamp
    if ($('.download-set.downloading').length > 0) {
      alert(tr.downloadInProgress)
      return
    }

    downloadSet.addClass('downloading')
    downloadSet.addClass(styles.downloading)

    zipUI.progressInit()
    var zip = new JSZip()

    $.each(files, function (index, file) {
      var jzFilename = file.url.replace(/.*\//g, '')
      zip.file(jzFilename, urlToPromise(file.url), { binary: true })
    })

    // when everything has been downloaded, we can trigger the dl
    zip
      .generateAsync({ type: 'blob' }, function updateCallback(metadata) {
        var percent = metadata.percent | 0
        var nextFile = {}
        if (metadata.currentFile) {
          var currentFile = metadata.currentFile
          nextFile = getNextFile(files, currentFile)
        }
        zipUI.progressUpdate(percent, nextFile)
      })

      .then(
        function callback(blob) {
          // see FileSaver.js
          saveAs(blob, filename + '.zip')
          zipUI.showMessage(tr.done)
          downloadSet.removeClass('downloading')
          downloadSet.removeClass(styles.downloading)
          setTimeout(deselectAll, 500)
        },
        function (e) {
          zipUI.showError(e)
        }
      )
  }

  const makeGroupFiles = (downloads, contentTypeGroups) => {
    const groupFiles = contentTypeGroups.map((group) => {
      let newDownloads = downloads.filter((item) => {
        return group.contentTypes.includes(item.download.internal.mediaType)
      })
      if (group.slug === 'product-images') {
        newDownloads = newDownloads.filter((item) => {
          return !item.download.name.includes('scene')
        })
      }
      if (group.slug === 'scene-images') {
        newDownloads = newDownloads.filter((item) => {
          return item.download.name.includes('scene')
        })
      }
      const newGroup = {
        contentTypes: group.contentTypes,
        title: group.title,
        downloads: newDownloads,
      }
      return newGroup
    })
    return groupFiles
  }

  const makeAllFiles = (products, contentTypeGroups) => {
    const newContentTypeGroups = contentTypeGroups.filter((group) =>
      ['product-images', 'scene-images'].includes(group.slug)
    )
    let allImageDownloads = []
    if (!products) products = [] // fix for collections with no products
    newContentTypeGroups.forEach((group) => {
      products.forEach((product) => {
        if (product.downloads) {
          product.downloads.forEach((download) => {
            if (group.contentTypes.includes(download.internal.mediaType)) {
              if (
                !allImageDownloads.find(
                  (allImageDownload) =>
                    allImageDownload.publicUrl === download.publicURL
                )
              ) {
                allImageDownloads.push(download)
              }
            }
          })
        }
      })
    })
    const productDownloads = allImageDownloads.filter(
      (download) => !download.name.includes('scene')
    )
    const sceneDownloads = allImageDownloads.filter((download) =>
      download.name.includes('scene')
    )
    const newDownloads = productDownloads.concat(sceneDownloads)
    return newDownloads
  }

  const contentTypeGroups = [
    {
      slug: 'product-images',
      title: tr.catProductImages,
      contentTypes: ['image/jpeg'],
    },
    {
      slug: 'scene-images',
      title: tr.catSceneImages,
      contentTypes: ['image/jpeg'],
    },
    {
      slug: '2d-3d-files',
      title: tr.cat2d3dFiles,
      contentTypes: [
        'image/vnd.dwg',
        'image/x-3ds',
        'application/dwg',
        'application/acad',
        'application/x-acad',
        'application/autocad_dwg',
        'image/x-dwg',
        'application/x-dwg',
        'application/x-autocad',
        'drawing/dwg',
      ],
    },
    {
      slug: 'spec-sheets',
      title: tr.catSpecSheets,
      contentTypes: ['application/pdf'],
    },
  ]

  let groupFiles, allFiles

  if (sourcePage === 'product') {
    groupFiles = makeGroupFiles(products[0].downloads, contentTypeGroups)
  }

  if (sourcePage === 'collection') {
    allFiles = makeAllFiles(products, contentTypeGroups)
  }
  return (
    <div
      className={`${styles.downloadSet} download-set
        ${selection.length > 0 ? 'has-files' : ''}
        `}
    >
      <div className={`${styles.files} files`}>
        {groupFiles &&
          groupFiles.map((group, i) => {
            if (group.downloads.length > 0) {
              return (
                <div className={styles.contentTypeGroup} key={i}>
                  <h5>{group.title}</h5>
                  <div className={styles.items}>
                    {group.downloads.map((download, i) => {
                      return (
                        <Download
                          key={i}
                          download={download}
                          selection={selection}
                          toggleSelection={toggleSelection}
                          // showProductName={showProductName}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            }
          })}

        {allFiles && (
          <div className={styles.items}>
            {allFiles.map((download, i) => {
              return (
                <Download
                  key={i}
                  download={download}
                  selection={selection}
                  toggleSelection={toggleSelection}
                  // showProductName={showProductName}
                />
              )
            })}
          </div>
        )}
      </div>

      <div className={`${styles.progress} progress`}>
        <div className={`${styles.progressBarOuter} progress-bar-outer`}>
          <div className={`${styles.progressBar} progress-bar`} />
        </div>
        <p className={`${styles.progressResult} progress-result`} />
      </div>

      <div className={styles.buttons}>
        <ButtonLink
          content={{ text: tr.selectAll }}
          onClick={
            selection.length === getAllItems().length ? deselectAll : selectAll
          }
          className={selection.length === getAllItems().length ? 'on' : ''}
        />
        <ButtonLink
          content={{
            text: tr.downloadFiles,
            isDisabled: selection.length === 0,
          }}
          onClick={selection.length === 0 ? null : zipDL}
          className={selection.length === 0 ? 'disabled' : ''}
        />
      </div>
    </div>
  )
}

export default Component

const Download = ({ download, selection, toggleSelection }) => {
  const file = download.download
  const filename = file.name + file.ext
  const { ext } = file
  return (
    <a
      className={`${
        selection.includes(file.publicURL) ? `${styles.on} on` : ''
      } ${file.name.includes('scene') ? styles.scene : ''}`}
      onClick={toggleSelection}
      data-url={file.publicURL}
      data-filename={file.name}
      data-size={file.size}
    >
      <div className={styles.checkbox}>
        <div className={styles.iconCheck}>
          <CheckboxSvg />
        </div>
      </div>
      {['.3ds', '.dwg', '.pdf'].includes(ext) && (
        <div className={styles.iconWrap}>
          <div className={styles.icon}>
            {ext === '.3ds' && <Icon3dsSvg />}
            {ext === '.dwg' && <IconDwgSvg />}
            {ext === '.pdf' && <IconPdfSvg />}
          </div>
          <div className={styles.text}>
            <p>{filename}</p>
          </div>
        </div>
      )}
      {/* {image.fluid && <GImage image={image} aspectRatio={1} />} */}
      {/* {fluid && <Img fluid={fluid} />} */}
      {file.childImageSharp && <ImageWrap image={file} aspectRatio={1} />}
    </a>
  )
}
