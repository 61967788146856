import React, { useState } from 'react'
import BannerProduct from 'blocks/BannerProduct/template'
import ImageThumbs from 'blocks/ImageThumbs/template'
import ProductLinks from 'blocks/ProductLinks/template'
import TextImageBgFull from 'blocks/TextImageBgFull/template'
import ProductSlideshowFromCollection from 'blocks/ProductSlideshowFromCollection/template'
import LoginOverlay from 'components/LoginOverlay'
import DownloadsOverlay from 'components/DownloadsOverlay'
import * as styles from './styles.module.scss'

const ContentType = ({ content, locale, layoutProps }) => {
  const [downloadsOverlayShow, setDownloadsOverlayShow] = useState(false)
  const [loginOverlayShow, setLoginOverlayShow] = useState(false)

  if (!locale) locale = 'en'
  const { product, productDiv, collectionProducts } = content
  const collection = product.collection?.data.frontmatter[locale]

  // const collectionProducts = products.filter(
  //   (product) => product.frontmatter[locale].collection === collection?.slug
  // )

  const collectionProductsLocale = collectionProducts.map(
    (product) => product.frontmatter[locale]
  )

  const strings = {
    en: {
      explore: 'Explore',
      collection: 'Collection',
      relatedProducts: 'Related Products',
    },
    zh: {
      explore: '探索更多',
      collection: '系列产品',
      relatedProducts: '相关产品',
    },
  }
  const tr = strings[locale]

  const blockContent = {
    bannerProduct: {
      productDiv: productDiv,
      pretitle: product.collection?.data.frontmatter[locale].title,
      pretitleLink: `/collections/${product.collection?.slug}`,
      title: product.title,
      excerpt: product.excerpt,
      image: product.imageCropVertical,
      description: product.description,
      linkTmall: product.linkTmall,
    },
    imageThumbs: {
      images: product.imageThumbs,
    },
    productLinks: {
      linkTaobao: product.linkTaobao,
    },
    textImageBgFull: {
      link: {
        text: tr.explore,
        to: `/collections/${collection?.slug}`,
        linkType: 'page',
      },
      pretitle: tr.collection,
      title: collection?.title,
      body: collection?.excerpt,
      image: collection?.images.main,
    },
    productSlideshowFromCollection: {
      pretitle: tr.relatedProducts,
      product: product,
      collectionProducts: collectionProductsLocale,
    },
  }

  const checkLogin = () => {
    const user = sessionStorage.getItem('user')
    if (user) {
      setDownloadsOverlayShow(true)
    } else {
      setLoginOverlayShow(true)
    }
  }
  const hasDownloads = product.downloads?.length > 0
  const bgClass = collection.productDiv?.slug === 'teaware' ? 'bg-white' : ''

  return (
    <div className={`${styles.page} ${bgClass}`}>
      {hasDownloads && (
        <>
          <LoginOverlay
            loginOverlayShow={loginOverlayShow}
            setLoginOverlayShow={setLoginOverlayShow}
            locale={locale}
          />
          <DownloadsOverlay
            products={[product]}
            downloadsOverlayShow={downloadsOverlayShow}
            setDownloadsOverlayShow={setDownloadsOverlayShow}
            locale={locale}
          />
        </>
      )}
      <BannerProduct content={blockContent.bannerProduct} locale={locale} />
      <ImageThumbs content={blockContent.imageThumbs} />
      <ProductLinks
        content={blockContent.productLinks}
        locale={locale}
        hasDownloads={hasDownloads}
        checkLogin={checkLogin}
      />
      <TextImageBgFull
        content={blockContent.textImageBgFull}
        layoutProps={layoutProps}
      />
      <ProductSlideshowFromCollection
        content={blockContent.productSlideshowFromCollection}
        locale={locale}
      />
    </div>
  )
}

export default ContentType
