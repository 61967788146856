import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { images } = content
  if (!images) return false

  return (
    <div className='pad-v-standard'>
      <Inview className='container grid-12 fade-in up'>
        {images.map((item, i) => (
          <div className={styles.image} key={i}>
            <ImageWrap image={item.image} aspectRatio={4 / 3} />
          </div>
        ))}
      </Inview>
    </div>
  )
}

export default Block
