import React from 'react'
import DownloadsGrid from 'components/DownloadsGrid'
import IconCloseSvg from '../../assets/svg/icons/icon-close.svg'
import * as styles from './styles.module.scss'

const Component = ({
  downloadsOverlayShow,
  setDownloadsOverlayShow,
  products,
  locale,
}) => {
  const strings = {
    en: {
      productDownloads: 'Product Downloads',
    },
    zh: {
      productDownloads: '产品下载',
    },
  }
  const tr = strings[locale]

  return (
    <div
      className={`${styles.component} ${
        downloadsOverlayShow ? styles.show : ''
      }`}
    >
      <div className={styles.overlay}>
        <a
          className={styles.closeIcon}
          onClick={() => setDownloadsOverlayShow(false)}
          data-state='downloadsOverlayShow'
        >
          <IconCloseSvg />
        </a>
        <div className={styles.inner}>
          <div className={styles.title}>
            <h5>{tr.productDownloads}</h5>
            <h2>{products[0].title}</h2>
          </div>
          <DownloadsGrid
            products={products}
            sourcePage='product'
            locale={locale}
          />
        </div>
      </div>
    </div>
  )
}

export default Component
